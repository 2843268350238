.form-section {
    padding-top: 5%;
}

.form-section h3 {
    text-align: center;
}

nav li {
    transition-duration: .5s;

}

nav li:hover {
    background-color: red;
    text-decoration: none;
    color: white;
}

.nav-link {
    color: black;
}

.nav-link:hover {
    color: white;
}

nav {
    text-transform: uppercase;
    font-weight: bold;
}

optgroup {
    font-weight: normal;
}

.form-section {
    background-color: rgb(250, 250, 250);
}

.form-section .container {
    background-color: #fff;
    border-radius: 1%;
    padding: 2%;
    box-shadow: 5px 10px 8px #888888;
}

.second-section span {
    color: rgb(255, 30, 67);
}

.second-section h2 {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
}

.second-section p {
    text-align: justify;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
}

.second-section button {
    width: 100%;
}

.form-section {
    padding-bottom: 5%;
}