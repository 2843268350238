.header-card {
    margin: auto;
    padding: 5%;
    text-align:justify;
    border-radius: 10px;
    left: 10%;
    background-color: #fff;
    /* Adjust this value to control the starting point of the border */

    box-shadow: 5px 5px 5px #00000011;
    border-bottom: #AF0305 2px solid;
    
}

.custom-button {
    background-color: #013955;
    color: white;
    width: auto;
    padding: 1%;
    border-radius: 5px;
    transition-duration: 0.25s;
}

.custom-button:hover {
    border: #013955 solid 2px;
    color: #013955;
    background-color: #fff;
}

.para-intro {
    color: #AF0305;
    font-size: 20px;
}

.nep-para {
    font-size: 20px;
}

.icon-image {
    width: 25%;
    transition: transform 0.3s;
    margin: auto;
}

.benefit-card {
    text-align: center;
    padding: 5%;
    box-shadow: 5px 5px 5px #00000011;
    border-radius: 10px;
    transition: transform 0.3s, background-color 0.3s;
    border-bottom: #fea700 solid 3px;
    margin-top: 3%;
    margin-bottom: 3%;
    background-color: #fff;
}

.benefit-card:hover .icon-image {
    transform: scale(1.25);
    transform: translateY(-10px);

}

.benefit-card:hover h5 {
    color: #fea700;
}

.benefit-card h5 {
    font-size: 17.5px;
    padding-top: 3%;
    padding-bottom: 3%;

}