@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'Raleway';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.contribute-header {
  background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 60%), url('../src/Components/assets/ContributeWithUs/banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  
}
.value {
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 60%), url('../src/Components/assets/ContributeWithUs/valueback.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.nav-logo{
  width: 125px;
}